import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Reviewer Guide`}</h1>
    <h2>{`Reviewers are responsible for editing and maintaining MakerDAO’s Community Development resources. All writers and contributors are welcome to assume the Reviewer role.`}</h2>
    <p>{`This guide introduces the review process and outlines editing standards. MakerDAO expects its Writers and Reviewers to adhere to the principles described within this guide and all other Comm-Dev `}<a parentName="p" {...{
        "href": "/contribute/"
      }}>{`contributor guides.`}</a></p>
    <Callout icon="warning" secondary mdxType="Callout">
      <p><strong parentName="p">{`Please Note:`}</strong>{` `}</p>
      <p>{`Reviewers should be comfortable working with Google Docs and HackMD. They should be open to learning the basics of Markdown, GitHub, and VSCode.`}</p>
    </Callout>
    <h2>{`Reviewer Roles`}</h2>
    <Box sx={{
      paddingX: 2
    }} mdxType="Box">
      <p>{`Reviewer roles for Community Development projects are divided into three categories.`}</p>
      <List mdxType="List">
        <Box sx={{
          paddingX: 4
        }} mdxType="Box">
          <p><strong parentName="p">{`Tone | Grammar`}</strong></p>
          <p>{`Reviewers assess content and resources for tone and consistency. They ensure that all documents adhere to the Writing Style Guide.`}</p>
          <ul>
            <li parentName="ul">{`This role does not require in-depth knowledge about MakerDAO.`}</li>
          </ul>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Content | Concepts`}</strong></p>
          <p>{`Reviewers edit content and make sure that all ideas presented are factual and easy to comprehend.`}</p>
          <ul>
            <li parentName="ul">{`Content Reviewers should have a deeper understanding of MakerDAO and its functions.`}</li>
          </ul>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Maintenance`}</strong></p>
          <p>{`Reviewers refer to document-specific maintenance guides to make sure that existing documents are standardized and up to date.`}</p>
          <ul>
            <li parentName="ul">{`Check GitHub for a document-specific maintenance guide before working on an existing resource.`}</li>
          </ul>
        </Box>
      </List>
    </Box>
    <h2>{`General Expectations`}</h2>
    <Box sx={{
      paddingX: 2
    }} mdxType="Box">
      <hr></hr>
      <ul>
        <li parentName="ul">{`Reviewers should be proficient writers with some background knowledge of cryptocurrencies and MakerDAO.`}</li>
        <li parentName="ul">{`They should be familiar with the Comm-Dev `}<a parentName="li" {...{
            "href": "/contribute/"
          }}>{`contributor guides.`}</a></li>
        <li parentName="ul">{`By and large, all contributors should focus on effective collaboration.`}</li>
      </ul>
      <Callout icon="warning" secondary mdxType="Callout">
        <p><strong parentName="p">{`Please Note:`}</strong>{` `}</p>
        <p>{`The Reviewer’s top priority is to make sure that concepts are clearly expressed, and that the writing and tone adhere to the `}<a parentName="p" {...{
            "href": "/contribute/content/writing-style-guide/"
          }}>{`Writing Style Guide.`}</a></p>
      </Callout>
    </Box>
    <h2>{`Reviewing an Existing Project`}</h2>
    <Box sx={{
      paddingX: 2
    }} mdxType="Box">
      <p>{`The guideline below offers general advice to ease the Reviewer's work process.`}</p>
      <hr></hr>
      <ul>
        <li parentName="ul">{`Existing documents may be at any stage:`}
          <ul parentName="li">
            <li parentName="ul">{`Google Doc`}</li>
            <li parentName="ul">{`HackMD`}</li>
            <li parentName="ul">{`VSCode`}</li>
            <li parentName="ul">{`Live on GitHub`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Give existing documents a full read-through before starting work.`}</li>
        <li parentName="ul">{`When possible, improve the clarity and presentation of the material.`}</li>
        <li parentName="ul">{`Check for and correct any typos, grammatical errors, and deviations from the `}<a parentName="li" {...{
            "href": "/contribute/content/writing-style-guide/"
          }}>{`Writing Style Guide.`}</a></li>
        <li parentName="ul">{`Expand on and update concepts as MakerDAO grows.`}
          <ul parentName="li">
            <li parentName="ul">{`Incorporate new developments within MakerDAO.`}</li>
            <li parentName="ul">{`Update documents to reflect the latest terminology and language as Maker grows and standards change.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Add cross-linking to relevant resources.`}</li>
        <li parentName="ul">{`Take into account that final iterations are converted to Markdown and published on GitHub.`}
          <ul parentName="li">
            <li parentName="ul">{`Avoid including direct links in a Google Doc.`}
              <ul parentName="li">
                <li parentName="ul">{`Use the Markdown format to simplify conversion later on.`}</li>
                <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` `}<inlineCode parentName="li">{`[link](https://link.com)`}</inlineCode></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul">{`Use the Google Docs heading presets.`}
          <ul parentName="li">
            <li parentName="ul">{`These work well with tools that convert documents into Markdown.`}</li>
            <li parentName="ul">{`Headings can also help structure a document.`}
              <ul parentName="li">
                <li parentName="ul">{`Google's outline tool follows the preset header formats.`}</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </Box>
    {
      /* While these could be used here and give the page a better flow I am not convinced they need to be added here.  */
    }
    {
      /* <Tout>
      <Box>
      ## Write Like Maker
      See what's expected when you write for the Maker community.
      [Writing Style Guide](/contribute/content/writing-style-guide/)
      </Box>
      <Box>
      ## Learn the Tools
      Take a look at the tools we use and why we use them.
      [Contributor Tools](/contribute/content/contributor-tools/)
      </Box>
      </Tout> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      